import BasicLayout from "../../layout/basic"

const promotionRoutes = [
	//******************组合套餐模块（2）******************
	{
		path: "/promotion/combo-*",
		name: "combo",
		meta: {
			module: "combo"
		},
		component: () => import("@/views/promotion/combo/detail")
	},
	{
		path: "/promotion/combo_payment",
		name: "combo_payment",
		meta: {
			module: "combo",
			auth: true
		},
		component: () => import("@/views/promotion/combo/payment")
	},

	//******************秒杀模块（3）******************
	{
		path: "/promotion/seckill-*",
		name: "seckill_detail",
		meta: {
			module: "seckill",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/promotion/seckill/detail")
	},
	{
		path: "/promotion/seckill",
		name: "seckill",
		meta: {
			module: "seckill",
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/promotion/seckill/list")
	},
	{
		path: "/promotion/seckill_payment",
		name: "seckill_payment",
		meta: {
			module: "seckill",
			auth: true
		},
		component: () => import("@/views/promotion/seckill/payment")
	},

	//******************团购模块（3）******************
	{
		path: "/promotion/groupbuy-*",
		name: "groupbuy_detail",
		meta: {
			module: "groupbuy",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/promotion/groupbuy/detail")
	},
	{
		path: "/promotion/groupbuy",
		name: "groupbuy",
		meta: {
			module: "groupbuy",
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/promotion/groupbuy/list")
	},
	{
		path: "/promotion/groupbuy_payment",
		name: "groupbuy_payment",
		meta: {
			module: "groupbuy",
			auth: true
		},
		component: () => import("@/views/promotion/groupbuy/payment")
	},

	//******************专题活动模块（4）******************
	{
		path: "/promotion/topic-*",
		name: "topic_detail",
		meta: {
			module: "topic"
		},
		component: () => import("@/views/promotion/topic/detail")
	},
	{
		path: "/promotion/topic",
		name: "topic",
		meta: {
			module: "topic"
		},
		component: () => import("@/views/promotion/topic/list")
	},
	{
		path: "/promotion/topic-goods-*",
		name: "topic_goods_detail",
		meta: {
			module: "topic",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/promotion/topic/goods_detail")
	},
	{
		path: "/promotion/topic_payment",
		name: "topic_payment",
		meta: {
			module: "topic",
			auth: true
		},
		component: () => import("@/views/promotion/topic/payment")
	}
]

const cmsRoutes = [{
		path: "/cms/notice",
		name: "notice",
		meta: {
			module: "notice",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/notice/list")
	},
	{
		path: "/cms/notice-*",
		name: "notice_detail",
		meta: {
			module: "notice",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/notice/detail")
	},
	{
		path: "/cms/help",
		name: "help",
		meta: {
			module: "help",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/help/list"),
		children: [{
			path: "/cms/help/listother-*",
			name: "list_other",
			meta: {
				module: "help",
				backgroundColor: "#fff"
			},
			component: () => import("@/views/cms/help/listother")
		}]
	},
	{
		path: "/cms/help-*",
		name: "help_detail",
		meta: {
			module: "help",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/help/detail")
	}
]

const goodsRoutes = [
	//******************商品模块（6）******************
	{
		path: "/brand",
		name: "brand",
		meta: {
			module: "goods",
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/goods/brand")
	},
	{
		path: "/cart",
		name: "cart",
		meta: {
			module: "goods"
		},
		component: () => import("@/views/goods/cart")
	},
	{
		path: "/category",
		name: "category",
		meta: {
			module: "goods"
		},
		component: () => import("@/views/goods/category")
	},
	{
		path: "/coupon",
		name: "coupon",
		meta: {
			module: "goods"
		},
		component: () => import("@/views/goods/coupon")
	},
	{
		path: "/sku-*",
		name: "detail",
		meta: {
			module: "goods",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/goods/detail")
	},
	{
		path: "/list",
		name: "list",
		meta: {
			module: "goods",
			title: '商品列表',
			backgroundColor: "#fff"
		},
		component: () => import("@/views/goods/list")
	},
	{
		path: "/street",
		name: "street",
		meta: {
			module: "shop",
			backgroundColor: "#fff",
			title: '店铺街'
		},
		component: () => import("@/views/shop/street")
	},
]

//商品分类
const newIn = [
	{
		path: "/newIn",
		name: "newIn",
		meta: {
			module: "newIn",
			// title: "Women"
		},
		component: resolve => require(['@/views/goodsSort/newIn'], resolve)
	},{
		path: "/productDetails",
		name: "productDetails",
		meta: {
			module: "productDetails"
		},
		component: resolve => require(['@/views/goodsSort/productDetails'], resolve)
	}, {
		path: "/sale",
		name: "sale",
		meta: {
			module: "sale"
		},
		component: resolve => require(['@/views/goodsSort/sale'], resolve)
	}, {
		path: "/dailyUpdate",
		name: "dailyUpdate",
		meta: {
			module: "dailyUpdate"
		},
		component: resolve => require(['@/views/goodsSort/dailyUpdate'], resolve)
	}, {
		path: "/search",
		name: "search",
		meta: {
			module: "search"
		},
		component: resolve => require(['@/views/goodsSort/search'], resolve)
	}, {
		path: "/Explore",
		name: "Explore",
		meta: {
			module: "Explore"
		},
		component: resolve => require(['@/views/goodsSort/Explore'], resolve)
	}
]

// 个人中心
const personal = [
	{
		path: "/personal",
		name: "personal",
		meta: {
			module: "personal"
		},
		// component: () => import("@/views/personal/personal"),
		component: resolve => require(['@/views/personal/personal'], resolve),
		children: [
			{
				path: "/personal",
				name: "index",
				meta: {
					auth: true
				},
				// component: () => import("@/views/personal/components/index"),
				component: resolve => require(['@/views/personal/components/index'], resolve),
			},{
				path: "/personal/address",
				name: "address",
				meta: {
					auth: true
				},
				// component: () => import("@/views/personal/components/address"),
				component: resolve => require(['@/views/personal/components/address'], resolve),
			},{
				path: "/personal/wishlist",
				name: "wishlist",
				meta: {
					auth: true
				},
				// component: () => import("@/views/personal/components/wishlist"),
				component: resolve => require(['@/views/personal/components/wishlist'], resolve),
			},{
				path: "/personal/footprint",
				name: "footprint",
				meta: {
					auth: true
				},
				// component: () => import("@/views/personal/components/footprint"),
				component: resolve => require(['@/views/personal/components/footprint'], resolve),
			},{
				path: "/personal/aboutSoyeg",
				name: "aboutSoyeg",
				meta: {
					auth: true
				},
				// component: () => import("@/views/personal/components/aboutSoyeg"),
				component: resolve => require(['@/views/personal/components/aboutSoyeg'], resolve),
			},{
				path: "/personal/returnPolicy",
				name: "returnPolicy",
				meta: {
					auth: true
				},
				// component: () => import("@/views/personal/components/returnPolicy"),
				component: resolve => require(['@/views/personal/components/returnPolicy'], resolve),
			},{
				path: "/personal/privacyNotice",
				name: "privacyNotice",
				meta: {
					auth: true
				},
				// component: () => import("@/views/personal/components/privacyNotice"),
				component: resolve => require(['@/views/personal/components/privacyNotice'], resolve),
			},{
				path: "/personal/contactUS",
				name: "contactUS",
				meta: {
					auth: true
				},
				// component: () => import("@/views/personal/components/contactUS"),
				component: resolve => require(['@/views/personal/components/contactUS'], resolve),
			},{
				path: "/personal/myAccount",
				name: "myAccount",
				meta: {
					auth: true
				},
				// component: () => import("@/views/personal/components/myAccount"),
				component: resolve => require(['@/views/personal/components/myAccount'], resolve),
			},{
				path: "/personal/modifyPassword",
				name: "modifyPassword",
				meta: {
					auth: true
				},
				// component: () => import("@/views/personal/components/modifyPassword"),
				component: resolve => require(['@/views/personal/components/modifyPassword'], resolve),
			},{
				path: "/personal/myOrders",
				name: "myOrders",
				meta: {
					auth: true
				},
				// component: () => import("@/views/personal/components/myOrders"),
				component: resolve => require(['@/views/personal/components/myOrders'], resolve),
			},{
				path: "/personal/myOrders/:i",
				name: "orderDetails",
				meta: {
					auth: true
				},
				// component: () => import("@/views/personal/components/orderDetails"),
				component: resolve => require(['@/views/personal/components/orderDetails'], resolve),
			},{
				path: "/personal/coupons",
				name: "coupons",
				meta: {
					auth: true
				},
				// component: () => import("@/views/personal/components/coupons"),
				component: resolve => require(['@/views/personal/components/coupons'], resolve),
			},{
				path: "/personal/logistics",
				name: "logistics",
				meta: {
					auth: true
				},
				// component: () => import("@/views/personal/components/logistics"),
				component: resolve => require(['@/views/personal/components/logistics'], resolve),
			},{
				path: "/personal/shippingInfo",
				name: "shippingInfo",
				meta: {
					auth: true
				},
				// component: () => import("@/views/personal/components/shippingInfo"),
				component: resolve => require(['@/views/personal/components/shippingInfo'], resolve),
			}
		]
	}
]

// 限时秒杀
const dailyDrops = [
	{
		path: "/dailyDrops",
		name: "dailyDrops",
		meta: {
			module: "dailyDrops"
		},
		// component: () => import("@/views/index/components/dailyDrops"),
		component: resolve => require(['@/views/index/components/dailyDrops'], resolve),
	}
]

// 潮流趋势
const trending = [
	{
		path: "/trending",
		name: "trending",
		meta: {
			module: "trending"
		},
		// component: () => import("@/views/index/components/trending"),
		component: resolve => require(['@/views/index/components/trending'], resolve),
	}
]

// Huge Deals
const hugeDeals = [
	{
		path: "/hugeDeals",
		name: "hugeDeals",
		meta: {
			module: "hugeDeals"
		},
		// component: () => import("@/views/index/components/hugeDeals"),
		component: resolve => require(['@/views/index/components/hugeDeals'], resolve),
	}
]

const login = [
	{
		path: "/login",
		name: "login",
		meta: {
			module: "login"
		},
		component: resolve => require(['@/views/login/login.vue'], resolve)
	}, {
		path: "/pivacy",
		name: "pivacy",
		meta: {
			module: "pivacy"
		},
		component: resolve => require(['@/views/login/components/pivacy'], resolve)
	}, {
		path: "/terms",
		name: "terms",
		meta: {
			module: "terms"
		},
		component: resolve => require(['@/views/login/components/terms'], resolve)
	}, {
		path: "/forgottenPassword",
		name: "forgottenPassword",
		meta: {
			module: "forgottenPassword"
		},
		component: resolve => require(['@/views/login/components/forgottenPassword'], resolve)
	}, {
		path: "/emailCode",
		name: "emailCode",
		meta: {
			module: "emailCode"
		},
		component: resolve => require(['@/views/login/components/emailCode'], resolve)
	}, {
		path: "/modifyPassword",
		name: "modifyPassword",
		meta: {
			module: "modifyPassword"
		},
		component: resolve => require(['@/views/login/components/modifyPassword'], resolve)
	},
]

// 底部页面
const footer = [
	{
		path: "/copyrightNotice",
		name: "copyrightNotice",
		meta: {
			module: "copyrightNotice"
		},
		component: resolve => require(['@/views/index/components/copyrightNotice'], resolve)
	}, {
		path: "/FAQ",
		name: "FAQ",
		meta: {
			module: "FAQ"
		},
		component: resolve => require(['@/views/index/components/FAQ'], resolve)
	}, {
		path: "/about",
		name: "about",
		meta: {
			module: "about"
		},
		component: resolve => require(['@/views/index/components/about'], resolve)
	}, {
		path: "/fashionBlogger",
		name: "fashionBlogger",
		meta: {
			module: "fashionBlogger"
		},
		component: resolve => require(['@/views/index/components/fashionBlogger'], resolve)
	}, {
		path: "/HowToOrder",
		name: "HowToOrder",
		meta: {
			module: "HowToOrder"
		},
		component: resolve => require(['@/views/index/components/HowToOrder'], resolve)
	}, {
		path: "/HowToPay",
		name: "HowToPay",
		meta: {
			module: "HowToPay"
		},
		component: resolve => require(['@/views/index/components/HowToPay'], resolve)
	}, {
		path: "/sizeGuide",
		name: "sizeGuide",
		meta: {
			module: "sizeGuide"
		},
		component: resolve => require(['@/views/index/components/sizeGuide'], resolve)
	},
]
// 其他模块
export default {
	path: "/",
	component: BasicLayout,
	redirect: "/index",
	name: "Index",
	children: [
		{
			path: "/index*",
			name: "index",
			meta: {
				mainCss: {
					width: "100%"
				},
				title: "Shop Trendy Women's Fashion | Womens Clothing | SOYEGO",
            	content: {
					keywords: 'Women、Men、Clothing、Shop Online Fashion、SOYEGO',
					description: "Discover affordable and fashionable women's clothing online at SOYEGO. Free Shipping On Orders $99+ ✓ Free Returns ✓ 1000+ New Arrivals Dropped Daily ✓"
				}
				// auth: true
			},
			// component: () => import("@/views/index/index"),
			component: resolve => require(['@/views/index/index'], resolve)
		},
		{
			path: "/change_city",
			name: "change_city",
			meta: {
				module: "index",
				// auth: true
			},
			// component: () => import("@/views/index/change_city"),
			component: resolve => require(['@/views/index/change_city'], resolve)
		},
		...footer,
		...goodsRoutes,
		...cmsRoutes,
		...promotionRoutes,
		...login,
		...newIn,
		...personal,
		...dailyDrops,
		...trending,
		...hugeDeals
	]
}
