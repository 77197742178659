
let baseApiUrl = 'https://api.soyego.com', imgDomainApi = 'https://api.soyego.com', webSocketUrl='https://api.soyego.com';

// if (location.hostname === 'localhost'){
//         // api请求地址
//         baseApiUrl='https://new.soyego.com';
//         // 图片域名
//         imgDomainApi='https://new.soyego.com';
//         // 客服
//         webSocketUrl='wss://new.soyego.com/wss';
// }else 
if(location.hostname === 'new.soyego.com'){
        baseApiUrl='https://new.soyego.com';
        // 图片域名
        imgDomainApi='https://new.soyego.com';
        // 客服
        webSocketUrl='wss://new.soyego.com/wss';
}
export default {
        // api请求地址
        baseUrl: baseApiUrl,

        // 图片域名
        imgDomain: imgDomainApi,

        // 腾讯地图key
        mpKey: '',

        // 客服
        webSocket: webSocketUrl,

        // api安全
        apiSecurity: "",

        //本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
        pingInterval: 1500,

        // 公钥
        publicKey: ``,
}