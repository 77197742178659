<template>
    <div class="header-in">
        <div class="nav" v-if="loading">
            <div :class="item.category_id == ativeTree ? 'item ative' : 'item'" v-for="(item, index) in treeList" :key="index" @click="treeClick(item.category_id)">
                <span>{{item.category_name}}</span>
            </div>
        </div>
        <router-link to="/" class="logo-wrap">
            <img v-if="siteInfo.logo" :src="$img(siteInfo.logo)" />
            <img v-else src="@/assets/images/logo.png" />
        </router-link>
        <!-- <p v-show="!search">Extra 10%OFF on first order</p> -->
        <div class="rigth">
            <span class="img1">
                <img v-show="search" class="img1X" src="@/assets/images/header/x.png" @click="searchClose" />
                <el-input v-show="search" placeholder="Search" v-model="searchText" @keydown.enter.native="keyDownClick"></el-input>
                <!-- <el-button icon="el-icon-search"></el-button> -->
                <img v-if="siteInfo.logo" :src="$img(siteInfo.logo)" />
                <img v-else @click="searchClick" src="@/assets/images/header/search.png" />
            </span>
            <span class="img2">
                <div class="imgHover">
                    <img v-if="siteInfo.logo" :src="$img(siteInfo.logo)" />
                    <img v-else src="@/assets/images/header/user.png" @click="$router.push('/personal');" />
                    <div class="img2Text">
                        <h5 v-if="userName != ''">Hi！{{userName}}</h5>
                        <h5 v-else @click="$router.push('/personal');" style="cursor: pointer;">Sign&nbsp;In/Register</h5>
                        <div class="textA">
                            <router-link to="/personal/myOrders">My&nbsp;&nbsp;Orders</router-link>
                            <!-- <router-link to="">My&nbsp;&nbsp;Message</router-link> -->
                            <router-link to="/personal/address">My&nbsp;&nbsp;Address</router-link>
                            <!-- <router-link to="">More&nbsp;&nbsp;Services</router-link> -->
                        </div>
                        <span v-if="userName != ''" @click="loginOut">Log out</span>
                    </div>
                </div>
            </span>
            <div class="rigth-xian"></div>
            <span class="img3">
                <div class="imgHover">
                    <img v-if="siteInfo.logo" :src="$img(siteInfo.logo)" />
                    <img v-else src="@/assets/images/header/shopping.png" @click="$router.push('/shopChart');" />
                    <!-- <span v-text="shoppNumber"></span> -->
                    <!-- <div class="img3Shopping">
                        <img src="@/assets/images/header/bag.png">
                        <p>Your&nbsp;&nbsp;shopping&nbsp;&nbsp;basket&nbsp;&nbsp;is&nbsp;&nbsp;empty&nbsp;.</p>
                    </div> -->
                </div>
            </span>
            <span class="img4">
                <div class="imgHover">
                    <img v-if="siteInfo.logo" :src="$img(siteInfo.logo)" />
                    <img v-else src="@/assets/images/header/wish.png" @click="$router.push('/personal/wishlist');" />
                    <!-- <span>{{collect}}</span> -->
                </div>
            </span>
            
            <span class="img5">
                <div class="imgHover">
                    <img v-if="siteInfo.logo" :src="$img(siteInfo.logo)" />
                    <img v-else src="@/assets/images/header/global_icon.png" @click="drawer = true" />
                </div>
            </span>
            <!-- <div class="rigth-xian"></div>
            <span class="img5" @click="service_link">
                <div class="imgHover">
                    <img src="@/assets/images/header/Union.png" />
                </div>
            </span> -->
        </div>
        <!--联系客服弹窗-->
		<!-- <servicerMessage ref="servicerMessage" class="kefu" :shop="{shop_id:0,logo:siteInfo.logo,shop_name:'平台客服'}">
		</servicerMessage> -->

        <!-- 初次进入选择地区弹窗 -->
        <div class="regionSele-popup" v-if="is_selectRegion">
            <div class="regionSele-title">WELCOME SOYEGO !</div>
            <div class="regionSele-logo">
                <img src="../../assets/images/soyego_logo.png" alt="">
            </div>
            <div class="regionSele-tips">
                <span class="tips-text">Please select Country</span>
            </div>
            <!-- 选择地区 -->
            <div class="regionSele-select">
                <el-select class="region-input" v-model="regionListIndex" placeholder="Please select region">
                    <el-option
                    v-for="(item,index) in regionList"
                    :key="index"
                    :label="item.country_item"
                    :value="index">
                        <span style="float: left">{{ item.country_item }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.country_simple }}</span>
                    </el-option>
                </el-select>
            </div>
            <!-- 提交 -->
            <div class="regionSele-submit" @click="switchRegion()">
                <span class="submit-text" >NEXT</span>
            </div>
        </div>
        <!-- 遮罩 -->
        <div class="regionSele-mask" v-if="is_selectRegion"></div>

        <!-- 右侧按钮选择语言 -->
        <el-drawer
        title="Region selection"
        :visible.sync="drawer"
        :modal-append-to-body="false"
        :with-header="false"
        size="50%">
            <div class="global-container">
                <div class="global-header">
                    <h3 class="header-title">Please select your region</h3>
                    <span class="header-tips">Please note that changing your location during re browsing will clear all items in the shopping bag</span>
                </div>
                <div class="global-main">
                    <div class="global-item" v-for="(item,index) in globalData" :key="index">
                        <div class="item-title">{{item.global_title}}</div>
                        <div class="item-country">
                            <div class="country-item" v-for="globalItem in item.global_country">
                                <span @click="switchLang(countryItem.country_code,countryItem.country_simple,countryItem.country_symbol)" v-for="countryItem in globalItem.country_data">
                                    {{countryItem.country_item}}/{{countryItem.country_simple}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { apiHotSearchWords, apiDefaultSearchWords } from "@/api/pc"
import { cartList as apiCartList } from "@/api/goods/cart"

import { getToken, removeToken } from "@/utils/auth"
import baseUrl from "@/utils/config"
import { info } from "@/api/member/info"

import { getCartCount } from "@/api/goods/cart"

import { shopServiceOpen } from "@/api/website.js"

import servicerMessage from "@/components/message/servicerMessage"

import { tree } from "@/api/goods/goodscategory"
import Axios from "axios"
export default {
    props: {},
    data() {
        return {
            img3ShoppingShow: false,
            img2TextShow: false,
            // rightStyle: "padding: 10px 0",
            search: false,
            searchText: "",
            searchType: "goods",
            searchTypeText: "商品",
            keyword: "",
            hotSearchWords: [],
            defaultSearchWords: "",
            cartList: [],
            cartTotalPrice: 0,
            shoppNumber: 0,     //购物
            collect: 0,         //收藏
            userName: "",
            loading: false,
            treeList: [],
            ativeTree: sessionStorage.getItem("treeOneIdSoyego") || '4',
            // 语言选择弹窗
            drawer: false,
            // 地区数据
            globalData: [
                {
                    global_title: 'North America',
                    global_country: [
                        {
                            country_data: [
                                {country_item: 'Canada', country_simple: 'CAD', country_code: 'en', country_symbol: 'C$'},
                                {country_item: 'U.S.A', country_simple: 'USD', country_code: 'en', country_symbol: '$'},
                            ]
                        }
                    ]
                },
                {
                    global_title: 'Europe',
                    global_country: [
                        {
                            country_data: [
                                {country_item: 'Austria', country_simple: 'EUR', country_code: 'en', country_symbol: '€'},
                                {country_item: 'Belgium', country_simple: 'EUR', country_code: 'en', country_symbol: '€'},
                                {country_item: 'Bulgaria', country_simple: 'BGN', country_code: 'en', country_symbol: 'лв'},
                                {country_item: 'Česká', country_simple: 'CZK', country_code: 'cs', country_symbol: 'K?'},
                                {country_item: 'Danmark', country_simple: 'DKK', country_code: 'da', country_symbol: 'kr'},
                                {country_item: 'Deutschland', country_simple: 'EUR', country_code: 'de', country_symbol: '€'},
                            ]
                        },
                        {
                            country_data: [
                                {country_item: 'España', country_simple: 'EUR', country_code: 'es', country_symbol: '€'},
                                {country_item: 'Suomi', country_simple: 'EUR', country_code: 'fi', country_symbol: '€'},
                                {country_item: 'France', country_simple: 'EUR', country_code: 'fr', country_symbol: '₣'},
                                {country_item: 'Magyarország', country_simple: 'HUF', country_code: 'hu', country_symbol: 'Ft'},
                                {country_item: 'Éirinn', country_simple: 'EUR', country_code: 'ga', country_symbol: '€'},
                                {country_item: 'Italia', country_simple: 'EUR', country_code: 'it', country_symbol: '€'},
                            ]
                        },
                        {
                            country_data: [
                                {country_item: 'Nederland', country_simple: 'EUR', country_code: 'nl', country_symbol: '€'},
                                {country_item: 'Norway', country_simple: 'NOK', country_code: 'no', country_symbol: 'NKr'},
                                {country_item: 'Polska', country_simple: 'EUR', country_code: 'pl', country_symbol: '€'},
                                {country_item: 'Portugal', country_simple: 'EUR', country_code: 'pt', country_symbol: '€'},
                                {country_item: 'România', country_simple: 'RON', country_code: 'ro', country_symbol: 'ROL'},
                                {country_item: 'Slovenija', country_simple: 'EUR', country_code: 'sl', country_symbol: '€'},
                            ]
                        },
                        {
                            country_data: [
                                {country_item: 'Sverige', country_simple: 'EUR', country_code: 'sv', country_symbol: '€'},
                                {country_item: 'Switzerland', country_simple: 'CHF', country_code: 'en', country_symbol: 'Fr'},
                                {country_item: 'Türkiye', country_simple: 'EUR', country_code: 'tr', country_symbol: '€'},
                                {country_item: 'britain', country_simple: 'GBP', country_code: 'en', country_symbol: '£'},
                            ]
                        },
                    ]
                },
                {
                    global_title: 'Asia',
                    global_country: [
                        {
                            country_data: [
                                {country_item: '中国大陆地区', country_simple: 'CNY', country_code: 'zh-CHS', country_symbol: '￥'},
                                {country_item: '한국', country_simple: 'KRW', country_code: 'ko', country_symbol: '₩'},
                                {country_item: '日本', country_simple: 'JPY', country_code: 'ja', country_symbol: '¥'},
                                {country_item: '中国香港特别行政区', country_simple: 'HKD', country_code: 'zh-CHT', country_symbol: 'HK$'},
                                {country_item: '中国台湾地区', country_simple: 'NTD', country_code: 'zh-CHT', country_symbol: 'NT$'},
                                {country_item: '新加坡', country_simple: 'SGD', country_code: 'zh-CHT', country_symbol: 'S$'},
                                {country_item: 'เมืองไทย', country_simple: 'THB', country_code: 'th', country_symbol: '฿'},
                                {country_item: 'Indonesia', country_simple: 'THB', country_code: 'id', country_symbol: 'Rp'},
                            ]
                        },
                    ]
                },
                {
                    global_title: 'Middle East',
                    global_country: [
                        {
                            country_data: [
                                {country_item: ' عرب .', country_simple: 'AED', country_code: 'ar', country_symbol: 'Fil'},
                                {country_item: 'السعوديين', country_simple: 'SAR', country_code: 'ar', country_symbol: 'SAR'},
                                {country_item: 'الكويت .', country_simple: 'KWD', country_code: 'ar', country_symbol: 'KWD'},
                                {country_item: ' قطر . ', country_simple: 'QAR', country_code: 'ar', country_symbol: 'QAR'},
                            ]
                        },
                    ]
                },
                {
                    global_title: 'Oceania',
                    global_country: [
                        {
                            country_data: [
                                {country_item: 'Australia', country_simple: 'AUD', country_code: 'en', country_symbol: 'A$'},
                                {country_item: 'New Zealand', country_simple: 'NZD', country_code: 'en', country_symbol: 'NZ$'},
                            ]
                        },
                    ]
                },
            ],
            // 初次选择地区内容
            regionListIndex: '',
            // 是否已选择地区
            is_selectRegion: false,
            // 初次进入选择地区弹窗 选择地区
            regionList: [
                {country_item: 'Canada', country_simple: 'CAD', country_code: 'en', country_symbol: 'C$', code: 'CA'},
                {country_item: 'U.S.A', country_simple: 'USD', country_code: 'en', country_symbol: '$', code: 'US'},
                {country_item: '中国大陆地区', country_simple: 'CNY', country_code: 'zh-CHS', country_symbol: '￥', code: 'CN'},
                {country_item: '中国香港特别行政区', country_simple: 'HKD', country_code: 'zh-CHT', country_symbol: 'HK$', code: 'HK'},
                {country_item: '中国台湾地区', country_simple: 'NTD', country_code: 'zh-CHT', country_symbol: 'NT$', code: 'TW'},
                {country_item: '한국', country_simple: 'KRW', country_code: 'ko', country_symbol: '₩', code: 'KR'},
                {country_item: '日本', country_simple: 'JPY', country_code: 'ja', country_symbol: '¥', code: 'JP'},
                {country_item: '新加坡', country_simple: 'SGD', country_code: 'zh-CHT', country_symbol: 'S$', code: 'SG'},
                {country_item: 'Austria', country_simple: 'EUR', country_code: 'en', country_symbol: '€', code: 'AT'},
                {country_item: 'Belgium', country_simple: 'EUR', country_code: 'en', country_symbol: '€', code: 'BE'},
                {country_item: 'Bulgaria', country_simple: 'BGN', country_code: 'en', country_symbol: 'лв', code: 'BG'},
                {country_item: 'Česká', country_simple: 'CZK', country_code: 'cs', country_symbol: 'K?', code: 'CZ'},
                {country_item: 'Danmark', country_simple: 'DKK', country_code: 'da', country_symbol: 'kr', code: 'DK'},
                {country_item: 'Deutschland', country_simple: 'EUR', country_code: 'de', country_symbol: '€', code: 'DE'},
                {country_item: 'España', country_simple: 'EUR', country_code: 'es', country_symbol: '€', code: 'ES'},
                {country_item: 'Suomi', country_simple: 'EUR', country_code: 'fi', country_symbol: '€', code: 'FI'},
                {country_item: 'France', country_simple: 'EUR', country_code: 'fr', country_symbol: '₣', code: 'FR'},
                {country_item: 'Magyarország', country_simple: 'HUF', country_code: 'hu', country_symbol: 'Ft', code: 'HU'},
                {country_item: 'Éirinn', country_simple: 'EUR', country_code: 'ga', country_symbol: '€', code: 'GB'},
                {country_item: 'Italia', country_simple: 'EUR', country_code: 'it', country_symbol: '€', code: 'IT'},
                {country_item: 'Nederland', country_simple: 'EUR', country_code: 'nl', country_symbol: '€', code: 'NL'},
                // {country_item: 'Norway', country_simple: 'NOK', country_code: 'no', country_symbol: 'NKr', code: ''},
                {country_item: 'Polska', country_simple: 'EUR', country_code: 'pl', country_symbol: '€', code: 'PL'},
                {country_item: 'Portugal', country_simple: 'EUR', country_code: 'pt', country_symbol: '€', code: 'PT'},
                {country_item: 'România', country_simple: 'RON', country_code: 'ro', country_symbol: 'ROL', code: 'RO'},
                {country_item: 'Slovenija', country_simple: 'EUR', country_code: 'sl', country_symbol: '€', code: 'SI'},
                {country_item: 'Sverige', country_simple: 'EUR', country_code: 'sv', country_symbol: '€', code: 'SE'},
                {country_item: 'Switzerland', country_simple: 'CHF', country_code: 'en', country_symbol: 'Fr', code: 'CH'},
                // {country_item: 'Türkiye', country_simple: 'EUR', country_code: 'tr', country_symbol: '€', colde: ''},
                {country_item: 'britain', country_simple: 'GBP', country_code: 'en', country_symbol: '£', code: 'GB'},
                {country_item: 'เมืองไทย', country_simple: 'THB', country_code: 'th', country_symbol: '฿', code: 'TH'},
                // {country_item: 'Indonesia', country_simple: 'THB', country_code: 'id', country_symbol: 'Rp', code: ''},
                {country_item: ' عرب .', country_simple: 'AED', country_code: 'ar', country_symbol: 'Fil', code: 'SA'},
                {country_item: 'السعوديين', country_simple: 'SAR', country_code: 'ar', country_symbol: 'SAR', code: 'SA'},
                // {country_item: 'الكويت .', country_simple: 'KWD', country_code: 'ar', country_symbol: 'KWD', },
                // {country_item: ' قطر . ', country_simple: 'QAR', country_code: 'ar', country_symbol: 'QAR'},
                {country_item: 'Australia', country_simple: 'AUD', country_code: 'en', country_symbol: 'A$', code: 'AU'},
                {country_item: 'New Zealand', country_simple: 'NZD', country_code: 'en', country_symbol: 'NZ$', code: 'NZ'},
            ]
        }
    },
    components: {
        servicerMessage
    },
    computed: {
        ...mapGetters(["cartCount", "siteInfo", "defaultGoodsImage", "member"])
    },
    created() {
        this.getLoginIn()
        this.keyword = this.$route.query.keyword || ""
        if (this.$route.name == "street") this.searchType = "shop"
        else this.searchType = "goods"
        this.$store.dispatch("site/siteInfo")
        this.getHotSearchWords()
        this.getDefaultSearchWords()
        this.getCartList()
        // this.getCartSum()
        this.getTree()
        sessionStorage.setItem("treeOneIdSoyego", this.ativeTree)
        // ip地址是否为国内
        // this.isDomestic();
    },
    watch: {
        searchType() {
            this.searchTypeText = this.searchType == "goods" ? "商品" : "店铺"
        },
        $route(curr) {
            if(this.$route.query){
                if(this.$route.query.Stab != undefined){
                    this.ativeTree = this.$route.query.Stab;
                    sessionStorage.setItem("treeOneIdSoyego", this.ativeTree);
                }
            }
            if (this.keyword !== curr.query.keyword) {
                this.keyword = curr.query.keyword;
            }
            if (this.$route.name == "street") this.searchType = "shop";
            else this.searchType = "goods";
            this.getLoginIn();
            this.getCartSum();
        },
        cartCount() {
            if (this.member) this.getCartList()
        },
        member() {
            if (!this.member) {
                this.$store.commit("cart/SET_CART_COUNT", 0)
                this.cartList = []
                this.cartTotalPrice = 0
            }
        },
    },
    methods: {
        // 是否为国内ip地址
        isDomestic() {
            // let parameters = 'key=b265eb1e47e0c21424c4dfa55f8f0b9a';
            // let url = window.location.href;
            // let p = url.split('?')[1];
            // let value = '';
            // 初次进入，弹出选择地区弹窗
            if(!localStorage.getItem("selectRegion") || localStorage.getItem("selectRegion") != 1){
                this.is_selectRegion = true;
            }
            // 获取高德id定位
            Axios.get('https://restapi.amap.com/v3/ip?'+parameters).then(res => {
                // 判断是否为手机端
                var sUserAgent = navigator.userAgent.toLowerCase()
                if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) return;
                // ip地址为国内
                if(res.data.status == '1'){
                    if(location.hostname != 'soyegokuajing.soyego.com'){
                        window.location.href = 'http://ch.soyego.com/';
                    }
                    // 是否从国内域名跳转而来
                    if(p){
                        value = decodeURI(p.split('=')[1]);
                        // 找到需要翻译的语种
                        this.regionList.forEach((item,index) => {
                            if(item.country_item == value){
                                // 根据携带的参数 翻译对应的语种
                                localStorage.setItem('translateCode', item.country_code);       // 有道云 语言码
                                localStorage.setItem('countrySoyego', item.country_simple);     // 货币简写
                                localStorage.setItem('strCurXSoyego', item.country_symbol);     // 货币符号
                                // 刷新页面，清除参数
                                // window.location.href = 'http://www.soyego.com/';
                                // location.reload();
                                // this.switchLang(item.country_code,item.country_simple,item.country_symbol);
                            }
                        });
                    }else{
                        // 自动跳转回国内服务器
                        window.location.href = 'http://home.soyego.com/';
                    }
                }else{
                    // 是否初次进入网站
                    if(!localStorage.getItem("selectRegion") || localStorage.getItem("selectRegion") != 1){
                        // 初次进入，弹出选择地区弹窗
                        this.is_selectRegion = true;
                    }
                }
            })
        },
        // 切换地区
        switchRegion() {
            let index = this.regionListIndex;
            let data = this.regionList[index];
            // let parameter = 'code='+data.country_code+'&simple='+data.country_simple+'&symbol='+data.country_symbol+'&is_selectRegion=true';
            let parameter = 'region='+data.country_item;
            if(data.country_code == 'zh-CHS'){
                // 已选择地区
                localStorage.setItem("selectRegion",1)
                // 跳转国内域名
                window.location.href = 'http://ch.soyego.com/?'+parameter;
            }else{
                // 已选择地区
                localStorage.setItem("selectRegion",1)
                localStorage.setItem('translateCode', data.country_code);       // 有道云 语言码
                localStorage.setItem('countrySoyego', data.country_simple);     // 货币简写
                localStorage.setItem('strCurXSoyego', data.country_symbol);     // 货币符号
                location.reload();
                // 切换语言并翻译
                // this.switchLang(data.country_code,data.country_simple,data.country_symbol);
            }  
        },
        // 是否初次进入
        // isFirstTime() {
        //     if(!localStorage.getItem("selectRegion") || localStorage.getItem("selectRegion") != 1){
        //         let that = this
        //         let url = window.location.href;
        //         let p = url.split('?')[1];
        //         // 是否从国内服务器跳转而来，并携带code参数
        //         if(p){
        //             let value = decodeURI(p.split('=')[1]);
        //             this.regionList.forEach((item,index) => {
        //                 if(item.country_item == value){
        //                     // 根据携带的参数 翻译对应的语种
        //                     localStorage.setItem('translateCode', item.country_code);  // 有道云 语言码
        //                     localStorage.setItem('countrySoyego', item.country_simple);   // 货币简写
        //                     localStorage.setItem('strCurXSoyego', item.country_symbol);   // 货币符号
        //                     location.reload();
        //                     // this.switchLang(item.country_code,item.country_simple,item.country_symbol);
        //                 }
        //             });
        //         }else{
        //             // 初次进入，弹出选择地区弹窗
        //             this.is_selectRegion = true;
        //         }
        //     }
        // },
        treeClick(id) {
            this.$router.push('/index?Stab=' + id);
        },
        getTree() {
            tree({
                level: 1
            }).then((res) => {
                if (res.code == 0 && res.data) {
                    this.treeList = res.data;
                    // 翻译
					let treeName = '';
                    res.data.forEach((item,index) => {
                        treeName += item.category_name + '\n'
                    })
                    this.loading = true;
                    this.$fanyi.translateData({
						query: treeName,
						success: resData => {
                            if(resData) {
                                res.data.forEach((item,index) => {
                                    // 重新渲染名称
                                    this.treeList[index].category_name = resData[index];
                                })
                            }
						}
					})
                }
            }).catch((err) => {
                this.$message.error(err.message)
            })
        },
        keyDownClick(e) {
            if(e.keyCode == 13){
                this.searchClick();
            }
        },
        service_link() {
            let token = getToken();
			if (token) {
				shopServiceOpen().then((res) => {
					if (res.code == 0) {
						if (res.data.type == 'third') {
							window.open(res.data.third, "_blank");
						} else if (res.data.type == 'system') {
							this.$refs.servicerMessage.show()
						}
					}
				})
			} else {
				this.$message({
					message: "您还未登录",
					type: "warning"
				})
			}
		},
        shopService() {
			shopServiceOpen().then((res) => {
                // console.log(res)
				if (res.code == 0) {
                    if (res.data.type == 'third') {
							window.open(res.data.third, "_blank");
						} else if (res.data.type == 'system') {
							this.$refs.servicerMessage.show()
						}
					// if (res.data.type == 'third') {
					// 	this.serverType = res.data.type
					// 	this.serverThird = res.data.third
					// } else if (res.data.type == 'system') {
					// 	this.serverType = res.data.type
					// 	this.serverThird = ''
					// }
				}
			})
		},
        getCartSum() {
            let token = getToken();
            if(token){
                getCartCount().then( res =>{
                    if(res.code == 0){
                        this.shoppNumber = res.data;
                    }
                })
            }else{
                this.shoppNumber = 0;
            }
        },
        loginOut() {
            removeToken();
            this.getLoginIn();
            this.$router.go(-1);
            // this.$router.push({ path: '/index' })
        },
        getLoginIn() {
            let token = getToken();
            if(token){
                info({ token: token }).then( res => {
                    if(res.code == 0 && res.data){
                        this.userName = res.data.nickname;
                    }
                }).catch( err => {
                    // console.log(err)
                })
            }else{
                this.userName = "";
            }
        },
        searchClick(){
            if(this.search && this.searchText != ""){
                this.$router.push({ path: '/search?keyword=' + this.searchText });
            }
            this.search = true;

        },
        searchClose(){
            this.search = false
        },
        handleCommand(command) {
            this.searchType = command
        },
        // search() {
        //     if (this.searchType == "goods") this.$router.push({ path: "/list", query: { keyword: this.keyword } })
        //     else this.$router.push({ path: "/street", query: { keyword: this.keyword } })
        // },
        getHotSearchWords() {
            apiHotSearchWords({}).then((res) => {
                if (res.code == 0 && res.data.words) {
                    this.hotSearchWords = res.data.words.split(",")
                }
            })
        },
        getDefaultSearchWords() {
            apiDefaultSearchWords({}).then((res) => {
                if (res.code == 0 && res.data.words) {
                    this.defaultSearchWords = res.data.words
                }
            })
        },
        // 获取购物车数据
        getCartList() {
            apiCartList({})
                .then((res) => {
                    if (res.code >= 0 && res.data.length) {
                        this.cartTotalPrice = 0
                        this.cartList = res.data
                        this.cartList.forEach((item) => {
                            this.cartTotalPrice += item.discount_price * item.num
                        })
                        this.cartTotalPrice = this.cartTotalPrice.toFixed(2)
                    }
                })
                .catch((res) => {})
        },
        imageError(index) {
            this.cartList[index].sku_image = this.defaultGoodsImage
        },
        // 删除单个购物车商品
        deleteCart(index) {
            this.$store
                .dispatch("cart/delete_cart", {
                    cart_id: this.cartList[index].cart_id.toString()
                })
                .then((res) => {
                    if (res.code >= 0) {
                        this.cartList.splice(index, 1);
                        this.$forceUpdate();
                    }
                })
                .catch((err) => {})
        },
        // 点击切换语言
        switchLang(code,simple,symbol) {
            if(code == 'zh-CHS' || code == 'zh-CHT'){
                let parameter = 'region='+code
                window.location.href=`https://home.soyego.com?`+parameter;
            }else{
                localStorage.setItem('translateCode', code);  // 有道云 语言码
                localStorage.setItem('countrySoyego', simple);   // 货币简写
                localStorage.setItem('strCurXSoyego', symbol);   // 货币符号
                location.reload();
            }
        }
    }
}
</script>

<style>
.rigth .img1 .el-input .el-input__inner{padding-right: 50px;}
</style>
<style scoped lang="scss">
.header-in {
    width: 100%;
    height: 84px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .nav{
        display: flex;
        position: absolute;
        left: 2vw;
        .item{
            cursor: pointer;
            margin-right: 20px;
            border-bottom: 2px solid transparent;
            span{
                color: #adadad;
                font: 900 19px proxima-nova,Arial,sans-serif;
                line-height: 1;
            }
        }
        .ative{
            // border-bottom: 2px solid #330000;
            span{
                // font-weight: 700;
                color: #330000;
            }
        }
    }

    .logo-wrap {
        display: block;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    >p{
        font-size: 12px;
        font-family: Montserrat;
        font-weight: 400;
        color: #FF5656;
        position: absolute;
        right: 29vw;
    }
	.rigth{
        height: 84px;
		position: absolute;
        right: 9.375%;
        display: flex;
        align-items: center;

        

        .img1{
            padding-right: 40px;
            display: flex;
            align-items: center;
            position: relative;
            >img{
                z-index: 99;
                cursor: pointer;
            }
            .img1X{
                position: relative;
                left: 20px;
            }
            .el-input{
                left: 40px;
            }
        }
        .img2{
            position: relative;
            .imgHover{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .imgHover:hover .img2Text{
                display: flex;
            }
            .img2Text{
                position: absolute;
                top: 66px;
                left: -50px;
                padding: 16px 20px;
                background: #FFFFFF;
                box-shadow: 0px 2px 12px 0px rgba(146, 150, 155, 0.4);
                display: none;
                flex-direction: column;
                z-index: 999;

                h5{
                    font-size: 12px;
                    font-family: Montserrat,SF-UI;
                    font-weight: bold;
                    color: #330000;
                    padding-bottom: 15px;
                    border-bottom: 1px solid #ECEEEE;
                }

                .textA{
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 20px;
                    a{
                        font-size: 12px;
                        font-family: Montserrat,SF-UI;
                        font-weight: 300;
                        color: #96989A;
                        padding-top: 14px;
                    }
                }
                

                span{
                    font-size: 12px;
                    font-family: Montserrat,SF-UI;
                    font-weight: 400;
                    color: #68696A;
                    line-height: 1;
                    padding-top: 20px;
                    border-top: 1px solid #ECEEEE;
                    cursor: pointer;
                }
            }
        }
        .img3{
            position: relative;

            .imgHover:hover .img3Shopping{
                display: flex;
            }
            .imgHover{
                height: 100%;
                display: flex;
                align-items: center;

                .img3Shopping{
                    position: absolute;
                    top: 80%;
                    right: -20px;
                    display: none;
                    // display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 31px 44px 38px;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 12px 0px rgba(146, 150, 155, 0.4);
                    z-index: 999;

                    p{
                        padding-top: 16px;
                        font-size: 12px;
                        font-family: Montserrat,SF-UI;
                        font-weight: 300;
                        color: #96989A;
                    }
                }
            }
        }

        .rigth-xian{
            width: 1px;
            height: 15px;
            margin: 0 30px;
            display: block;
            background: #C4C7CA;
        }
        >span{
            height: 100%;
            .imgHover{
                display: flex;
                height: 100%;
                align-items: center;
                >img{
                    cursor: pointer;
                    z-index: 99;
                }
                >span{
                    font-size: 14px;
                    font-family: Montserrat,SF-UI;
                    font-weight: 400;
                    color: #A6AAAD;
                    padding-left: 11px;
                    vertical-align: middle;
                }
            }
            
        }
        .img4{
            margin-left: 30px;
        }
        .img5{
            margin-left: 30px;
            .imgHover{
                display: flex;
                height: 100%;
                align-items: center;
                img{
                    width:24px;
                    height:24px;
                }
            }
        }
	}
}
.el-drawer__wrapper /deep/ .el-drawer__body {
    // overflow: hidden;
    overflow-y: scroll;
}
.global-container{
    height:100%;
    .global-header{
        display: flex;
        flex-direction: column;
        padding:20px;
        background-color: #F5F5F5;
        
        .header-title{
            font-size: 16px;
            font-family: Montserrat-Semi Bold, Montserrat;
            font-weight: 700;
            color: #170000;
            line-height: 16px;
        }
        .header-tips{
            font-size: 12px;
            font-family: Montserrat-Regular, Montserrat;
            font-weight: 400;
            color: #ADA6A6;
            line-height: 12px;
            margin-top: 12px;
        }
    }
    .global-main{
        min-height: 600px;
        padding:20px;
        display: flex;
        flex-wrap: wrap;
        .global-item{
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
            &:first-child{
                margin-left: 0;
            }
            .item-title{
                font-size: 16px;
                font-family: Montserrat-Regular, Montserrat;
                font-weight: 400;
                color: #170000;
                line-height: 16px;
            }
            .item-country{
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
                padding-bottom: 10px;
                .country-item{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    padding:0px 10px 10px 0px;
                    &:first-child{
                        margin-left: 0;
                    }
                    span{
                        min-width:100px;
                        padding-right: 30px;
                        font-size: 12px;
                        line-height:24px;
                        font-family: Montserrat-Regular, Montserrat;
                        font-weight: 400;
                        color: #857E7E;
                        cursor:pointer;
                        &:hover{
                            color:#330000;
                        }
                    }
                }
            }
        }
    }
}
// 首次进入选择地区
.regionSele-popup{
    width:600px;
    height:506px;
    background-color: #FFFFFF;
    position: fixed;
    left:calc(50% - 300px);
    top:calc(50% - 303px);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
    padding:40px 0px;
    .regionSele-title{
        font-size: 34px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #330000;
        line-height: 34px;
    }
    .regionSele-logo{
        width:106px;
        height:104px;
        margin-top:30px;
        .image{
            width:100%;
            height:100%;
        }
    }
    .regionSele-tips{
        width:366px;
        height:1px;
        background-color: #857E7E;
        margin-top:40px;
        position: relative;
        display: flex;
        justify-content: center;
        .tips-text{
            font-size: 16px;
            font-family: Montserrat-Medium, Montserrat;
            font-weight: 500;
            color: #333333;
            line-height: 16px;
            position: absolute;
            top:-10px;
            background-color: #FFFFFF;
            padding:0px 6px;
        }
    }
    .regionSele-select{
        width:366px;
        margin-top:50px;
        .region-input{
            width:100%;            
        }
    }
    .regionSele-submit{
        width:366px;
        height:50px;
        border:1px solid #330000;
        margin-top:40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor:pointer;
        .submit-text{
            font-size: 16px;
            font-family: Montserrat-Semi Bold, Montserrat;
            font-weight: normal;
            color: #330000;
            line-height: 16px;
        }
    }
}
// 遮罩
.regionSele-mask{
    width:100%;
    height:100%;
    background-color: #000;
    opacity: 0.7;
    position: fixed;
    left:0;
    top:0;
    z-index: 9998;
}
</style>
