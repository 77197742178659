<template>
    <div class="header">
        <div class="header-activity">
            <span>Free shipping on orders over $59</span>
        </div>
        <div class="header-top">
            <div class="top-container">
                <div class="top-navList">
                    <div class="navList-item" 
                    v-for="(item,index) in navList"
                    :class="index==navIndex?'cur':''"
                    @click="switchNavType(index,item.category_id)">
                        <span>{{item.category_name}}</span>
                    </div>
                </div>
                <div class="header-logo" @click="$router.push({ path: '/'})">
                    <el-image :src="require('@/assets/images/soyego_logo2.png')" fit="cover" />
                </div>
                <div class="top-menu">
                    <div class="menu-search">
                        <el-input v-model='searchValue' placeholder='Search'></el-input>
                        <div class="search_icon" @click="searchClick()">
                            <el-image :src="require('@/assets/images/search_icon.png')" fit="cover" />
                        </div>
                    </div>
                    <div class="menu-list">
                        <div class="list-item" @click="$router.push('/personal');">
                            <el-image :src="require('@/assets/images/header_my.png')" fit="cover" />
                        </div>
                        <div class="list-item" @click="$router.push('/shopChart');">
                            <el-image :src="require('@/assets/images/header_cart.png')" fit="cover" />
                        </div>
                        <div class="list-item" @click="$router.push('/personal/wishlist');">
                            <el-image :src="require('@/assets/images/header_collection.png')" fit="cover" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-secondList">
            <div class="secondList-container">
                <div class="secondList-item" @click="$router.push({ path: '/newIn?categoryId=' + -1})"><span>Sale</span></div>
                <div class="secondList-item" @click="$router.push({ path: '/newIn?categoryId=' + -2 })"><span>Clothing</span></div>
                <div class="secondList-item" 
                v-for="(item,index) in secondList"
                @click="$router.push({ path: '/newIn?categoryId=' + item.category_id })">
                    <span>{{ item.category_name }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { tree, categoryConfig, goodsCategoryObtain, topLevel } from "@/api/goods/goodscategory"
export default {
    data() {
        return {
            // 搜索框内容
            searchValue: '',
            // 模板类型列表
            navList: [], 
            // 模板列表下标
            navIndex: localStorage.getItem('navIndex') || 0,
            // 二级模板列表
            secondList: [],
        }
    },
    created() {
        this.getTreeData();
    },
    methods: {
        // 搜索
        searchClick(){
            if(this.searchValue != ""){
                this.$router.push({ path: '/search?keyword=' + this.searchValue });
            }
        },
        // 获取模板类型数据
		getTreeData() {
			var that = this;
            const params = {
				level: 2
			}
			tree(params || {}).then(res => {
                if (res.code == 0 && res.data) {
                    // console.log(res);
                    that.navList = res.data;
                    that.secondList = that.navList[that.navIndex].child_list;
                    // console.log('123123',that.secondList)
                }
            }).catch(err => {
                
            })
		},
        // 切换首页模板类型
		switchNavType(index,categoryId) {
			var that = this;
			// if(index == that.navIndex) return;
			that.navIndex = index;
			localStorage.setItem('treeOneId', categoryId);
			localStorage.setItem('navIndex', index);
			switch(categoryId) {
				case 4 : localStorage.setItem('indexTemplateSoyego', "DIY_VIEW_INDEX");break;
				case 171 : localStorage.setItem('indexTemplateSoyego', "DIY_VIEW_INDEX_2");break;
				case 7 : localStorage.setItem('indexTemplateSoyego', "DIY_VIEW_INDEX_3");break;
				case 193 : localStorage.setItem('indexTemplateSoyego', "DIY_VIEW_INDEX_4");break;
				case 194 : localStorage.setItem('indexTemplateSoyego', "DIY_VIEW_INDEX_5");break;
				case 9 : localStorage.setItem('indexTemplateSoyego', "DIY_VIEW_INDEX_6");break;
				case 15 : localStorage.setItem('indexTemplateSoyego', "DIY_VIEW_INDEX_7");break;
				default : break;
			}
            this.$router.push({ path: '/'});
			location.reload();
		},
    }
}
// import NsHeaderTop from "./NsHeaderTop"
// import NsHeaderMid from "./NsHeaderMid"
// import { tree, categoryConfig, goodsCategoryObtain, topLevel } from "@/api/goods/goodscategory"

// import { navList } from "@/api/website"
// import { f } from "core-js/internals/object-get-own-property-names"
// import { keys, values } from "core-js/internals/enum-bug-keys"
// export default {
//     props: {
//         forceExpand: {
//             type: Boolean,
//             default: false
//         }
//     },
//     components: {
//         NsHeaderTop,
//         NsHeaderMid
//     },
//     data() {
//         return {
//             isShopHover: false,
//             isIndex: false,
//             thisRoute: "",
//             goodsCategoryTree: [],
//             categoryConfig: {},
//             selectedCategory: -1,
//             navList: [],
//             navSelect: "",
//             isHide: false,
//             classify: [],
//             loading: true,
//             index: -1,
//             valIndex: -100,
//             timeText: "",
//             timeList: {
//                 timeTamp: [],
//                 timeDate: []
//             },
//             timeAtive: 0,
//             timeTamp: {
//                 start_time: "",
//                 end_time: ""
//             },
//             categoryId: sessionStorage.getItem("treeOneIdSoyego") || 4,
//             categoryIdList: [],
//             list: [],
//             // 需要翻译的静态文字
//             translateTextData: {
//                 NEW_IN: 'NEW IN',
//                 NEW_IN_TODAY: 'NEW IN TODAY',
//                 SALE: 'SALE',
//             }
//         }
//     },
//     computed: {},
//     beforeCreate() {},
//     created() {
//         // 翻译静态文本
//         this.getTranslateText()

//         // this.$store.dispatch("cart/cart_count")
//         // this.getCategoryConfig()
//         this.getTree();
//         this.getTreeList();
//         // this.getTreeListObtain();
//         // this.nav();
//         if (this.$route.path == "/" || this.$route.path == "/index") this.isIndex = true;
//         this.getTimeList();
//     },
//     mounted() {},
//     watch: {
//         $route: function (curr) {
//             // this.isShopHover = false;
//             // this.initNav(curr.path)
//             if (curr.path == "/" || curr.path == "/index") this.isIndex = true
//             else this.isIndex = false

//             if (curr.path == "/list") {
//                 this.navSelect = ""
//             }
//              if(this.$route.query){
//                 if(this.$route.query.Stab != undefined){
//                     this.categoryId = this.$route.query.Stab;
//                     this.getTreeList();
//                 }
//             }
//         }
//     },
//     methods: {
//         // 翻译静态文本
//         getTranslateText() {
//             let strText = '';
//             let i = 0;
//             let translateData = Object.values(this.translateTextData); // 创建一个包含对象中每个属性的值的数组
//             translateData.forEach((item,index) => {
//                 strText += item + '\n'
//             })
//             // 翻译文本
//             this.$fanyi.translateData({
//                 query: strText,
//                 success: resData => {
//                     for(let key in this.translateTextData){
//                         this.translateTextData[key] = resData[i];
//                         i++;
//                     }
//                 }
//             })
            
//         },
//         getTreeListObtain(val) {
//             goodsCategoryObtain({ category_id: val }).then( res => {
//                 if(res.code == 0 && res.data){
//                     this.classify = [];
//                     this.classify = res.data.child_list;
                    
//                     // 翻译
//                     let strData = '';
//                     res.data.child_list.forEach((item,index) => {
//                         strData += item.category_name + '\n'
//                     })
                    
//                     this.$fanyi.translateData({
// 						query: strData,
// 						success: resData => {
//                            res.data.child_list.forEach((item,index) => {
//                                 this.classify[index].category_name = resData[index];
//                             })
// 						}
// 					})
//                 }
//             })
//         },
//         getTreeList() {
//             goodsCategoryObtain({ category_id: this.categoryId }).then( res => {
//                 if(res.code == 0 && res.data){
//                     this.navList = res.data.child_list;
//                     // 翻译
//                     let strData = '';
//                     res.data.child_list.forEach((item,index) => {
//                         strData += item.category_name + '\n'
//                     })
//                     this.$fanyi.translateData({
// 						query: strData,
// 						success: resData => {
//                             res.data.child_list.forEach((item,index) => {
//                                 this.navList[index].category_name = resData[index];
//                             })
// 						}
// 					})
//                 }
//             })
//         },
//         // 获取顶部菜单栏数据
//         getMenubar() {
//             // topLevel({  }).then( res => {
                
//             // })
//         },
//         getTree() {
//             tree({
//                 level: 3,
//             }).then((res) => {
//                 if (res.code == 0 && res.data) {
//                     this.list = res.data;
//                 }
//             })
//         },
//         getTimeList() {
//             let date = new Date();
//             this.getTime(date);
//         },
//         getYue(month) {
//             switch(month) {
//                 case 1 : this.timeText = "January";break;
//                 case 2 : this.timeText = "February";break;
//                 case 3 : this.timeText = "March";break;
//                 case 4 : this.timeText = "April";break;
//                 case 5 : this.timeText = "May";break;
//                 case 6 : this.timeText = "June";break;
//                 case 7 : this.timeText = "July";break;
//                 case 8 : this.timeText = "August";break;
//                 case 9 : this.timeText = "September";break;
//                 case 10 : this.timeText = "October";break;
//                 case 11 : this.timeText = "November";break;
//                 case 12 : this.timeText = "December";break;
//                 default : this.timeText = "";break;
//             }
//         },
//         getTime(date) {
//             let year = date.getFullYear();
//             let day = date.getDate();
//             let t = new Date();
//             t.setMonth(t.getMonth());
//             let month = t.getMonth();
//             let date2 = new Date(year, month, 0)
// 	        let rq = date2.getDate();
            
//             for(let i = 0, j = 0; i < 7; i++){
//                 var time = "";
//                 if(day - i < 1) {
//                     let month = date.getMonth();
//                     this.getYue(month);
//                     this.timeList.timeTamp[i] = this.getTimetamp(month, rq - j, i);
//                     time = this.timeText + " " + (rq - j) + "," + year;
//                     j++;
//                 }else if(day - i < 10){
//                     let month = date.getMonth() + 1;
//                     this.getYue(month);
//                     this.timeList.timeTamp[i] = this.getTimetamp(month, day - i, i);
//                     time = this.timeText + " " + "0" + (day - i) + "," + year;
//                 }else if(day - i >= 10) {
//                     let month = date.getMonth() + 1;
//                     this.getYue(month);
//                     this.timeList.timeTamp[i] = this.getTimetamp(month, day - i, i);
//                     time = this.timeText + " " + (day - i) + "," + year;
//                 }
//                 this.timeList.timeDate[i] = time;
//             }
//             if(this.timeIndex >= 0) {
//                 this.timeTamp = this.timeList.timeTamp[this.timeIndex];
//             }
//         },
//         getTimetamp(month, tiemDate, index){
//             // console.log(month, tiemDate)
//             var data = new Date();
//             data.setMonth(month - 1)
//             data.setDate(tiemDate);
//             data.setHours(0);
//             data.setSeconds(0);
//             data.setMinutes(0);
//             // // dateObject 的月份字段，使用本地时间。返回值是 0（一月） 到 11（十二月） 之间的一个整数。
//             var data1 = new Date(); // 下一年
//             data1.setMonth(month - 1);
//             data1.setDate(tiemDate);
//             data1.setHours(23);
//             data1.setSeconds(59);
//             data1.setMinutes(59);
//             // console.log('当天0点' + data.getTime())
//             // console.log('当天23点' + data1.getTime())
//             var timeStart = parseInt(data.getTime() / 1000);
//             var timeEnd = parseInt(data1.getTime() / 1000); //(计算当前23点的时间戳-1即可)
//             var time = {
//                 start_time: timeStart,
//                 end_time: timeEnd
//             }
//             if(index == 0){
//                 this.timeTamp = time;
//             }
//             return time;
//         },
        // 获取配置
        // getCategoryConfig() {
        //     categoryConfig({})
        //         .then((res) => {
        //             if (res.code == 0 && res.data) {
        //                 this.categoryConfig = res.data
        //                 this.getTree(res.data)
        //             }
        //         })
        //         .catch((err) => {
        //             this.$message.error(err.message)
        //         })
        // },
        // getTree(val) {
        //     tree({
        //         level: 3,
        //     }).then((res) => {
        //             // console.log(val)
        //             if (res.code == 0 && res.data) {
        //                 // console.log(res)
        //                 if (val > 0) {
        //                     res.data.forEach((item) => {
        //                         if (item.category_id == val) {
        //                             // console.log()
        //                             this.classify = item
        //                         } else {
        //                             if (item.child_list.length > 0) {
        //                                 item.child_list.forEach((item1) => {
        //                                     if (item1.category_id == val) {
        //                                         // console.log()
        //                                         this.classify = item1
        //                                     }
        //                                 })
        //                             }
        //                         }
        //                         // console.log(this.classify)
        //                     })
        //                 } else {
        //                     this.classify = res.data
        //                 }
        //                 // console.log(this.classify)
        //                 if (this.classify.length <= 0) {
        //                     this.isShopHover = false
        //                 }
        //                 // console.log(res.data)
        //                 this.loading = false
        //             }
        //         })
        //         .catch((err) => {
        //             this.$message.error(err.message)
        //         })
        // },
        // getCaption(obj) {
        //     var index = obj.lastIndexOf("?")
        //     if (index != -1) {
        //         var i = obj.lastIndexOf("=")
        //         obj = obj.substring(i + 1, obj.length)
        //         return obj
        //     } else {
        //         return ""
        //     }
        // },
        // nav() {
        //     navList({})
        //         .then((res) => {
        //             // console.log(res)
        //             if (res.code == 0 && res.data) {
        //                 this.navList = res.data.list
        //                 for (let i in this.navList) {
        //                     // this.navList[i]["url"] = "/" + JSON.parse(this.navList[i]["nav_url"]).url;
        //                     this.navList[i]["url"] = JSON.parse(this.navList[i]["nav_url"]).url
        //                 }
        //                 // console.log(this.navList)
        //                 this.initNav(this.$route.path)
        //             }
        //         })
        //         .catch((err) => {
        //             this.$message.error(err.message)
        //         })
        // },
        // navUrl(url, target) {
        //     if (!url) return
        //     if (url.indexOf("http") == -1) {
        //         if (target) this.$router.pushToTab({ path: url })
        //         else this.$router.push({ path: url })
        //     } else {
        //         if (target) window.open(url)
        //         else window.location.href = url
        //     }
        // },
        // initNav(path) {
        //     for (let i in this.navList) {
        //         if (this.navList[i]["url"] == path) {
        //             this.navSelect = path
        //             continue
        //         }
        //     }
        // },
        //鼠标移入显示商品分类
        // shopHover(val, index) {
        //     this.loading = true
        //     this.isShopHover = true
        //     // console.log(index)
        //     this.index = index
        //     val = this.getCaption(val)

        //     this.valIndex = val
        //     // if(val != "") {
        //     this.getTree(val)
        //     // }else{
        //     // this.isShopHover = false;
        //     // }
        //     // console.log(val)
        // },
        // //鼠标移出商品分类隐藏
        // shopLeave() {
        //     this.isShopHover = false
        // }
//     }
// }
</script>

<!-- <style scoped lang="scss">
.header {
    width: 100%;

    .shadow {
        box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
    }
    .border {
        border: 1px solid #f5f5f5;
    }

    .mask{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.5);
    }
}
nav {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 10px 20px 0px rgba(135, 138, 142, 0.1);
    border-top: 1px solid #eceeee;
    position: relative;
    ul {
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        li {
            // cursor: pointer;
            list-style: none;
            // margin-right: 104px;
            margin-right: 60px;
            padding: 21px 0;

            .item{
                // position: relative;

                .ative {
                    color: #330000;
                    font-weight: bold;
                    border-bottom: 3px solid #330000;
                }
                >span{
                    color: #330000;
                    font: 600 16px proxima-nova,Arial,sans-serif;
                    cursor: pointer;
                }

                .newIn-list{
                    position: absolute;
                    width: calc(100% - 320px);
                    left: 0;
                    top: 68px;
                    z-index: 999;
                    background: #ffffff;
                    display: none;
                    padding: 40px 160px 40px;

                    .item{
                        padding-left: 60px;
                        display: flex;
                        flex-direction: column;

                        >h5{
                            color: #330000;
                            font: 900 16px proxima-nova,Arial,sans-serif;
                            line-height: 1;
                            padding-bottom: 20px;
                        }

                        >div{
                            display: flex;
                            padding-bottom: 20px;

                            span{
                                cursor: pointer;
                                font: 400 14px proxima-nova,Arial,sans-serif;
                                line-height: 1;
                                color: #330000;
                            }
                        }
                    }

                    .item-two{
                        .item-list{
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;
                            max-height: 644px;

                            .item-list-item{
                                padding-left: 120px;
                                padding-bottom: 50px;
                                display: flex;
                                flex-direction: column;

                                >h5{
                                    font: 900 16px proxima-nova,Arial,sans-serif;
                                    color: #330000;
                                    line-height: 1;
                                    padding-bottom: 20px;
                                }

                                >div{
                                    display: flex;
                                    padding-bottom: 20px;

                                    span{
                                        cursor: pointer;
                                        font: 400 14px proxima-nova,Arial,sans-serif;
                                        line-height: 1;
                                        color: #330000;
                                    }
                                }
                            }
                        }
                    }
                }

                .sale-list{
                    position: absolute;
                    width: calc(100% - 320px);
                    left: 0;
                    top: 68px;
                    z-index: 999;
                    background: #ffffff;
                    display: none;
                    padding: 40px 160px 40px;

                    .item-img{
                        width: 220px;
                        height: 182px;
                        margin-left: 200px;
                    }

                    .item-two{
                        .item-list{
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;
                            max-height: 644px;
                            // padding-left: 70px;
                            padding-left: 200px;

                            .item-list-item:last-child{
                                padding-right: 0;
                            }
                            .item-list-item{
                                padding-right: 120px;
                                padding-bottom: 50px;
                                display: flex;
                                flex-direction: column;

                                >h5{
                                    font: 900 16px proxima-nova,Arial,sans-serif;
                                    color: #330000;
                                    line-height: 1;
                                    padding-bottom: 20px;
                                }

                                >div{
                                    display: flex;
                                    padding-bottom: 20px;

                                    span{
                                        cursor: pointer;
                                        font: 400 14px proxima-nova,Arial,sans-serif;
                                        line-height: 1;
                                        color: #330000;
                                    }
                                }
                            }
                        }
                    }
                }

                .list{
                    position: absolute;
                    width: calc(100% - 320px);
                    left: 0;
                    top: 68px;
                    z-index: 999;
                    background: #ffffff;
                    display: none;
                    flex-direction: column;
                    align-items: center;
                    align-content: center;
                    padding: 40px 160px 40px;

                    >h5{
                        font: 900 16px proxima-nova,Arial,sans-serif;
                        color: #330000;
                        line-height: 1;
                        padding-bottom: 20px;
                    }

                    .classifyList{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        

                        >div{
                            // display: flex;
                            // flex-wrap: wrap;
                            max-width: 690px;
                            word-wrap: break-word;
                            // justify-content: center;

                            >span{
                                cursor: pointer;
                                min-width: 200px;
                                margin-right: 30px;
                                font: 400 14px proxima-nova,Arial,sans-serif;
                                color: #330000;
                                line-height: 1;
                                padding-bottom: 14px;
                                display: inline-block;
                            }
                        }
                        
                    }
                }
            }
            .item:hover{
                >span{
                    color: #330000;
                    font-weight: bold;
                    border-bottom: 3px solid #330000;
                    padding-bottom: 22px;
                }
                .list{
                    display: flex;
                }
                .newIn-list{
                    display: flex;
                }
                .sale-list{
                    display: flex;
                }
            }
            
            // a {
            //     &:hover {
            //         color: $base-color;
            //     }
            // }
        }
        // li:hover {
        //     color: $base-color;
        //     font-weight: bold;
        // }
        // .router-link-active {
        //     color: $base-color;
        //     font-weight: bold;
        //     // border-bottom: 2px solid  #FD274A;
        // }
    }

    .nav_list {
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        z-index: 999;
        background: #ffffff;

        .list {
            display: flex;
            width: 55%;

            .img {
                max-width: 220px;
                width: 100%;
                height: 292px;
                margin-right: 126px;
            }

            .nav_list-two {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                // max-height: 450px;
                // height: 100%;

                .item {
                    display: flex;
                    flex-direction: column;
                    // flex-wrap: wrap;
                    padding-right: 30px;
                    padding-bottom: 30px;

                    > h5 {
                        padding-top: 10px;
                        padding-bottom: 20px;
                        line-height: 1;
                        font-size: 16px;
                        font-family: Montserrat,SF-UI;
                        font-weight: bold;
                        color: #330000;
                        cursor: pointer;
                    }

                    > span {
                        padding-bottom: 14px;
                        line-height: 1;
                        font-size: 14px;
                        font-family: Montserrat,SF-UI;
                        font-weight: 400;
                        color: #330000;
                        cursor: pointer;
                    }
                }
            }

            .img-right{
                max-width: 220px;
                width: 100%;
                height: 182px;
                margin-left: 120px;
            }

            .time{
                display: flex;
                flex-direction: column;
                padding-right: 143px;

                h5{
                    font-size: 16px;
                    font-family: Montserrat,SF-UI;
                    font-weight: bold;
                    color: #330000;
                    line-height: 1;
                    padding-bottom: 20px;
                }
                >div{
                    display: flex;
                    flex-direction: column;

                    span{
                        font-size: 14px;
                        font-family: Montserrat,SF-UI;
                        font-weight: 400;
                        color: #330000;
                        line-height: 1;
                        padding-bottom: 14px;
                        cursor: pointer;
                    }
                }
                
            }
        }
    }
}
</style> -->

<style lang="scss">
    .header{
        width: 100%;
        border-bottom: 1px solid #efefef;
        .header-activity{
            width: 100%;
            height: 44px;
            background-color: #000000;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span{
                color: #fff;
                font-size: 14px;
                line-height: 14px;
                font-family: Bold;
            }
        }
        .header-top{
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: #FFF;
            .top-container{
                width: 90.62vw;
                padding: 20px 0px;
                display: flex;
                justify-content: space-between;
                position: relative;
                .top-navList{
                    display: flex;
                    .navList-item{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-right: 20px;
                        cursor: pointer;
                        &.cur{
                            span{
                                color:#333333;
                            }
                        }
                        span{
                            color: #999999;
                            font-size: 14px;
                            line-height: 14px;
                            font-family: Bold;
                            font-weight: 600;
                        }
                    }
                }
                .header-logo{
                    width: 12.65vw;
                    height: calc(12.65vw * 36 / 243);
                    position: absolute;
                    left: calc(50% - 12.65vw / 2);
                    cursor: pointer;
                    .el-image{
                        width: 100%;
                        height: 100%;
                    }
                }
                .top-menu{
                    display: flex;
                    align-items: center;
                    .menu-search{
                        width: 202px;
                        height: 36px;
                        border: 1px solid #ADA6A6;
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0px 10px 0px 10px;
                        background-color: #FFF;
                        .el-input{
                            width: 160px;
                            height: 30px;
                            input{
                                width: 100%;
                                height: 100%;
                                border:none;
                                padding:0;
                            }
                        }
                        .search_icon{
                            width: 20px;
                            height: 20px;
                            margin-left: 10px;
                            cursor: pointer;
                            .el-image{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .menu-list{
                        display: flex;
                        margin-left: 20px;
                        .list-item{
                            width: 20px;
                            height: 20px;
                            padding: 0px 20px;
                            border-right: 1px solid #ADA6A6;
                            cursor: pointer;
                            &:last-child{
                                padding-right:0;
                                border: none;
                            }
                            .el-image{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
        .header-secondList{
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 20px;
            .secondList-container{
                display: flex;
                // justify-content: space-between;
                .secondList-item{
                    margin-right: 30px;
                    font-family: Bold;
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: 600;
                    padding-bottom: 12px;
                    cursor: pointer;
                    // border-bottom: 2px solid #333;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
</style>

<!-- 媒体查询 小于970px -->
<style lang="scss" scoped>
@media (max-width: 970px) {
    .header{
        .header-top{
            .top-container{
                .header-logo{
                    // width: 39px;
                    // height: 38px;
                    // position: absolute;
                    // left: calc(50% - 20px);
                    // top: 10px;
                    .el-image{
                        width: 100%;
                        height: 100%;
                    }
                }
                .top-menu{
                    display: flex;
                    align-items: center;
                    .menu-search{
                        width: 142px;
                        height: 36px;
                        border: 1px solid #ADA6A6;
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0px 10px 0px 10px;
                        background-color: #FFF;
                        .el-input{
                            width: 100px;
                            height: 30px;
                            input{
                                width: 100%;
                                height: 100%;
                                border:none;
                                padding:0;
                            }
                        }
                        .search_icon{
                            width: 20px;
                            height: 20px;
                            margin-left: 10px;
                            cursor: pointer;
                            .el-image{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .menu-list{
                        display: flex;
                        margin-left: 20px;
                        .list-item{
                            width: 20px;
                            height: 20px;
                            padding: 0px 20px;
                            border-right: 1px solid #ADA6A6;
                            &:last-child{
                                padding-right:0;
                                border: none;
                            }
                            .el-image{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    
}
</style>
