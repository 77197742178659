import CryptoJS from "crypto-js"
import $, { type } from 'jquery'

export default {
    singleData: localStorage.getItem('translateCode'),

    // 数据翻译
    translateData(params) {
		// let appKey = '428fe61d45b3c064',
            // key = 'TZg3gN838wdABebjqJgApSNnxt0Iiaw3',
        let appKey = '09c88f74955be099',
            key = 'SKI6AZ1iCwx9UXPqWUezGc4np9MeZUWK',
            salt = (new Date).getTime(),
            curtime = Math.round(new Date().getTime()/1000),
            query = params.query,
            from = params.from || 'en',
            to = this.singleData || 'en';
        let str1 = appKey + this.truncate(query) + salt + curtime + key;
        let sign = CryptoJS.SHA256(str1).toString(CryptoJS.enc.Hex);
        let translation = '',
            translationArr = [];
        $.ajax({
            url: 'https://openapi.youdao.com/api',
            type: 'post',
            dataType: 'jsonp',
            data: {
                q: query,
                appKey: appKey,
                salt: salt,
                from: from,
                to: to,
                sign: sign,
                signType: "v3",
                curtime: curtime,
            },
            success: function (resData) {
                if(resData.errorCode == 0 && resData.translation){
                    resData.translation.forEach((item, index) => {
                        translationArr = item.split('\n')
                    })
                    // 判断数组最后一个元素是否为空
                    if(translationArr.slice(-1) == '') translationArr.pop();
                    if(translationArr.length <= 1) {
                        translation = translationArr[0];
                        typeof params.success == 'function' && params.success(translation);
                    }else{
                        typeof params.success == 'function' && params.success(translationArr);
                    }
                }else{
                    console.log('有道翻译错误代码：',resData.errorCode);
                    translationArr = params.query.split('\n');
                    // 判断数组最后一个元素是否为空
                    if(translationArr.slice(-1) == '') translationArr.pop();
                    if(translationArr.length <= 1) {
                        translation = translationArr[0];
                        typeof params.success == 'function' && params.success(translation);
                    }else{
                        typeof params.success == 'function' && params.success(translationArr);
                    }
                }
            } 
        });
    },

    // 单数据翻译 
    single(dataQuery) {
        console.log(this.singleData)
    },

    // 翻译 async表示这是一个异步函数
    // async translate(dataQuery,dataTo) {
    //     // 有道翻译api
	// 	let appKey = '428fe61d45b3c064',
    //     key = 'TZg3gN838wdABebjqJgApSNnxt0Iiaw3',
    //     salt = (new Date).getTime(),
    //     curtime = Math.round(new Date().getTime()/1000),
    //     query = dataQuery,
    //     from = 'en',
    //     to = dataTo || 'en';

    //     let str1 = appKey + this.truncate(query) + salt + curtime + key;
    //     let sign = CryptoJS.SHA256(str1).toString(CryptoJS.enc.Hex);
    //     let mydata = await $.ajax({
    //         url: 'https://openapi.youdao.com/api',
    //         type: 'post',
    //         dataType: 'jsonp',
    //         data: {
    //             q: query,
    //             appKey: appKey,
    //             salt: salt,
    //             from: from,
    //             to: to,
    //             sign: sign,
    //             signType: "v3",
    //             curtime: curtime,
    //         },
    //     });
    //     return mydata.translation[0];
    // },

    truncate(q) {
		var len = q.length;
		if(len<=20) return q;
		return q.substring(0, 10) + len + q.substring(len-10, len);
	},
}