import http from "../utils/http"

/**
 * 楼层
 * @param {object} params
 */
export function floors(params) {
    return http({
        url: "/api/pc/floors",
        data: params
    })
}

/**
 * 获取热门搜索关键词
 */
export function apiHotSearchWords(params) {
    return http({
        url: "/api/pc/hotSearchWords",
        data: params
    })
}

/**
 * 获取默认搜索关键词
 */
export function apiDefaultSearchWords(params) {
    return http({
        url: "/api/pc/defaultSearchWords",
        data: params
    })
}
/**
 * 获取首页浮层
 */
export function floatLayer(params) {
    return http({
        url: "/api/pc/floatLayer",
        data: params
    })
}

/**
 * 获取首页商品分类
 */
export function diyviewInfo(params) {
    return http({
        url: "/api/diyview/info",
        data: params
    })
}

/**
 * 获取首页爆款商品列表
 */
export function blowoutSaleInfo(params) {
    return http({
        url: "/api/goodssku/page",
        data: params
    })
}
/**
 * 获取货币
 */
export function configGetArea(params) {
    return http({
        url: "/api/config/getArea",
        data: params
    })
}

/**
 * 获取商品详情尺码
 */
export function goodsGetSizeGuied(params) {
    return http({
        url: "/api/goods/getSizeGuied",
        data: params
    })
}

/**
 * 获取所有国家
 */
export function addressCountryLists() {
    return http({
        url: "/api/address/countryLists"
    })
}

/**
 * 添加收货地址
 */
 export function memberaddressAdd(params) {
    return http({
        url: "/api/memberaddress/add",
        data: params
    })
}

/**
 * 修改收货地址
 */
 export function memberaddressEdit(params) {
    return http({
        url: "/api/memberaddress/edit",
        data: params
    })
}

/**
 * 删除收货地址
 */
 export function memberaddressDelete(params) {
    return http({
        url: "/api/memberaddress/delete",
        data: params
    })
}

/**
 *  获取支付方式
 */
 export function payPcType() {
    return http({
        url: "/api/pay/pcType"
    })
}

/**
 *  获取支付成功的数据
 */
 export function paypalStatus(paymentId) {
    return http({
        url: "/api/paypal/status",
        data: paymentId
    })
}

/**
 *  获取支付成功的数据
 */
//  export function paypalInfo(paymentId) {
//     return http({
//         url: "/api/paypal/info",
//         data: paymentId
//     })
// }


/**
 *  判断是否成功
 */
 export function paypalCallback(params) {
    return http({
        url: "/api/paypal/Callback",
        data: params
    })
}

/**
 *  获取支付成功的数据
 */
 export function orderGetOrderDeail(params) {
    return http({
        url: "/api/order/getOrderDeail",
        // method: "post",
        data: params,
        // forceLogin: true
    })
}

/**
 *  获取sale折扣列表
 */
 export function discountPage(params) {
    return http({
        url: "/discount/api/discount/page",
        // method: "post",
        data: params,
        // forceLogin: true
    })
}

/**
 *  多个商品添加到购物车
 */
 export function addCarts(params) {
    return http({
        url: "/api/cart/addlist",
        // method: "post",
        data: params,
        // forceLogin: true
    })
}

/**
 *  获取优惠券id
 */
 export function getPlatformcouponId(params) {
    return http({
        url: "/api/ordercreate/getPlatformcouponId",
        // method: "post",
        data: params,
        // forceLogin: true
    })
}

/**
 *  获取所有平台优惠券
 */
export function getPlatformcouponTypelists() {
    return http({
        url: "/platformcoupon/api/platformcoupon/typelists"
    })
}

/**
 *  判断平台优惠券是否可以领取
 */
 export function judgePlatformcoupon(params) {
    return http({
        url: "platformcoupon/api/platformcoupon/receivedNum",
        data: params
    })
}

/**
 *  获取平台优惠券
 */
 export function getPlatformcouponReceive(params) {
    return http({
        url: "platformcoupon/api/platformcoupon/receive",
        data: params
    })
}