<template>
    <div id="app" :style="background">
        <transition name="slide"><router-view v-if="isRouterAlive" /></transition>
        
        <div class="gotop" v-show="gotop" @click="toTop">
            <img src="@/assets/images/header/TOP.png" />
        </div>
    </div>
</template>

<script>
import baseUrl from "@/utils/config"
// import { ColorThief } from "node_modules/color-thief-2.3.2/dist/color-thief.mjs"
// import ColorThief from "colorthief";
// import ColorThief from './node_modules/color-thief-2.3.2/dist/color-thief.mjs'
import { configGetArea } from "@/api/pc"
import Axios from "axios"
export default {
    name: "APP",
    provide() {
        return {
            reload: this.reload
        }
    },
    data: () => {
        return {
            background: "background: #fff",
            gotop: false,
            isRouterAlive: true
        }
    },
    watch: {
        $route: "fetchData"
    },
    created() {
        // 判断是否手机端
        this.browserRedirect();
        // 判断是否国内IP
        // this.isDomestic();

        // 获取url参数
        let params = this.getRequestParams();
        if(params['suid'] != '' && params['suid'] != undefined && params['suid'] != null) {
			// uni.setStorageSync('soyegoPromotionSuid', params['suid']);
            localStorage.setItem("soyegoPromotionSuid",params['suid']);
		}
    },
    
    mounted() {
        window.addEventListener("scroll", this.handleScroll, true);
        // const colorThief = new ColorThief();
        // console.log(colorThief)
        if (this.$route.path == "/login") {
            this.background = "background: transparent"
        } else {
            this.background = "background: #fff"
        }

        if (localStorage.getItem("countrySoyego") == null || localStorage.getItem("countrySoyego") == undefined || localStorage.getItem("strCurXSoyego") == null || localStorage.getItem("strCurXSoyego") == undefined) {
            this.getMoney()
        }
    },
    methods: {
        isDomestic() {
            let parameters = 'key=b265eb1e47e0c21424c4dfa55f8f0b9a';
            // 获取高德IP定位
            Axios.get('https://restapi.amap.com/v3/ip?'+parameters).then(res => {
                // ip地址为国内
                if(res.data.status == '1'){
                    if(location.hostname != 'soyegokuajing.soyego.com'){
                        window.location.href = 'http://ch.soyego.com/';
                    }
                }
            })
        },
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function (){
                this.isRouterAlive = true;
            })
        },
        handleScroll() {
            let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
            scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
        },
        toTop() {
            let top = document.documentElement.scrollTop || document.body.scrollTop;
            // 实现滚动效果 
            const timeTop = setInterval(() => {
                document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
                if (top <= 0) {
                    clearInterval(timeTop);
                }
            }, 10);
        },
        browserRedirect() {
            var sUserAgent = navigator.userAgent.toLowerCase()
            if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
                let url = baseUrl.baseUrl
                url = url.replace("://api.", "://m.")
                url = url.replace("://new.", "://m.")
                let urlPath = window.location.pathname;
                let searchUrl = window.location.search;
                if(urlPath == "/productDetails") {
                    url = url + "/pages/goods/detail/detail" + searchUrl;
                    window.location.href = url;
                }else {
                    url = url + searchUrl
                    window.location.href = url;
                }

                // if(urlPath == "/productDetails"){
                //     if(searchUrl != undefined && searchUrl != null && searchUrl != ""){
                //         url = url + "/pages/goods/detail/detail" + searchUrl;
                //         window.location.href = url;
                //     }else{
                //         window.location.href = url;
                //     }
                // } else{
                //     window.location.href = url;
                // }
            }
        },
        // 获取url参数
		getRequestParams() {
			let url = location.href;
			let requestParams = {};
			if (url.indexOf('?') !== -1) {
				let str = url.substr(url.indexOf('?') + 1); //截取?后面的内容作为字符串
				// console.log(str, '?后面的内容');
				let strs = str.split('&'); //将字符串内容以&分隔为一个数组
				// console.log(strs, '以&切割的数组');
				for (let i = 0; i < strs.length; i++) {
					requestParams[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1]);
					// 将数组元素中'='左边的内容作为对象的属性名，'='右边的内容作为对象对应属性的属性值
				}
			}
			console.log(requestParams, '处理后的对象');
			return requestParams;
		},
        fetchData() {
            if (this.$route.path == "/login") {
                this.background = "background: transparent"
            } else {
                this.background = "background: #fff"
            }
        },
        getMoney() {
            configGetArea({}).then((res) => {
                // if (res.data.country == "USD") {
                //     res.data.country = "US"
                // }
                localStorage.setItem("countrySoyego", res.data.country);
                localStorage.setItem("strCurXSoyego", res.data.str_cur_x);
            })
        }
    }
}
</script>
<style>
.el-loading-spinner .path {
    stroke-width: 4px;
    stroke: #330000;
}
.gotop {
  position: fixed;
  right: 36px;
  bottom: 110px;
  cursor: pointer;
  z-index: 9999999;
}
</style>
<!-- <style lang="scss" scoped>
/*每个页面公共css */
@import url("assets/styles/iconfont.css");
@import "@/assets/font/font.scss";
@import "assets/styles/main.scss";
</style> -->
