<template>
    <div style="width: 100%" v-show="show">
        <!-- 关于我们 -->
        <div class="about-content" style="background: #F7F9FA;">
            <div class="about-content-warp">
                <div class="warp-text">
                    <div class="aboutUs">
                        <h5>{{translateTextData.COMPANY_INFO}}</h5>
                        <span @click="$router.push('/about')">{{translateTextData.About_SOYEGO}}</span>
                        <span @click="$router.push('/fashionBlogger')">{{translateTextData.Fashion_Blogger}}</span>
                    </div>
                    <div class="aboutUs">
                        <h5>{{translateTextData.HELP_SUPPORT}}</h5>
                        <span @click="$router.push('/personal/shippingInfo')">{{translateTextData.Shipping_Info}}</span>
                        <span @click="$router.push('/personal/returnPolicy')">{{translateTextData.Returns_Policy}}</span>
                        <span @click="$router.push('/HowToOrder')">{{translateTextData.How_To_Order}}</span>
                        <span @click="$router.push('/sizeGuide')">{{translateTextData.Size_Guide}}</span>
                    </div>
                    <div class="aboutUs">
                        <h5>{{translateTextData.CUTSOMER_CARE}}</h5>
                        <span @click="$router.push('/personal/contactUS')">{{translateTextData.Contact_US}}</span>
                        <span @click="$router.push('/FAQ')">{{translateTextData.FAQ}}</span>
                        <span @click="$router.push('/HowToPay')">{{translateTextData.How_To_Pay}}</span>
                        <!-- <span>Bouns Point</span>
                        <span>Afterpay</span>
                        <span>Recall Notice</span> -->
                    </div>
                    <div class="text-img">
                        <h5>{{translateTextData.FIND_US_ON}}</h5>
                        <div>
                            <img src="@/assets/images/fackbook.png" @click="textImgClick('https://www.facebook.com/SOYEGOOFFICIAL')" />
                            <!-- <img src="@/assets/images/twitter.png" @click="textImgClick('https://twitter.com/SOYEGO_OFFICIAL')" /> -->
                            <img src="@/assets/images/ins.png" @click="textImgClick('https://www.instagram.com/soyego_official')" />
                            <img src="@/assets/images/tiktok.png" @click="textImgClick('https://www.tiktok.com/@soyego_official')" />
                        </div>
                    </div>
                    <!-- <div class="text-img">
                        <h5>DOWNLOAD SOYEGO APP</h5>
                        <div>
                            <img src="@/assets/images/apple.png" />
                            <img src="@/assets/images/android.png" />
                        </div>
                    </div> -->
                    <!-- <div class="banner">
                        <img src="@/assets/images/off.png" />
                    </div> -->
                </div>
                <div class="warp-we">
                    <div class="text">
                        <h5>{{translateTextData.WE_ACCEPT}}</h5>
                        <div>
                            <img src="@/assets/images/paypal 1.png" />
                            <img src="@/assets/images/visa 1.png" />
                            <img src="@/assets/images/mastercard 1.png" />
                            <img src="@/assets/images/图层 601 1.png" />
                            <img src="@/assets/images/club 1.png" />
                            <img src="@/assets/images/union pay 1.png" />
                            <img src="@/assets/images/jcb 1.png" />
                            <img src="@/assets/images/cb 1.png" />
                            <img src="@/assets/images/InteracLogo 1.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 100%; height: 1px; background: #eceeee"></div>
            <div class="about-content-bottom">
                <span> ©2021-2024 SOYEGO.com All Rights Reserved </span>
                <a href="http://beian.miit.gov.cn" target="_blank" rel="nofollow">粤ICP备2022077218号-1</a>
                <div class="text">
                    <router-link to="/copyrightNotice"><u>{{translateTextData.Copyright_Notice}}</u></router-link>
                    <router-link to="/pivacy"><u>{{translateTextData.Privacy_Policy}}</u></router-link>
                    <router-link to="/terms"><u>{{translateTextData.Terms_and_Conditions}}</u></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
//尾部关于我们
.about-content {
    width: 100%;
    padding-top: 94px;
    // background: #fff;

    .about-content-warp {
        // padding-left: 32.9%;
        // padding-right: 34.8%;
        padding-bottom: 60px;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;

        .warp-text {
            display: flex;
            justify-content: center;
            position: relative;

            > div {
                padding-right: 70px;
            }

            .aboutUs {
                display: flex;
                flex-direction: column;

                h5 {
                    font-size: 14px;
                    font-family: Montserrat,SF-UI;
                    font-weight: Medium;
                    color: #330000;
                    line-height: 1;
                    padding-bottom: 20px;
                }
                span {
                    line-height: 1;
                    font-size: 14px;
                    font-family: Montserrat,SF-UI;
                    font-weight: 300;
                    color: #330000;
                    padding-bottom: 14px;
                    cursor: pointer;
                }
                span:last-child {
                    padding-bottom: 0;
                }
            }

            .text-img {
                h5 {
                    font-size: 14px;
                    font-family: Montserrat,SF-UI;
                    font-weight: Medium;
                    color: #330000;
                    padding-bottom: 20px;
                    line-height: 1;
                }
                img {
                    margin-right: 20px;
                    cursor: pointer;
                }
            }

            .banner {
                position: absolute;
                right: 16.5vw;
                bottom: -134px;
            }
        }

        .warp-we {
            display: flex;
            // flex-direction: column;
            // align-items: center;
            justify-content: center;
            // margin-left: 15.5vw;
            padding-top: 70px;

            .text{
                width: 57%;
                display: flex;
                flex-direction: column;

                h5 {
                    line-height: 1;
                    font-size: 14px;
                    font-family: Montserrat,SF-UI;
                    font-weight: Medium;
                    color: #330000;
                    padding-bottom: 20px;
                }

                img{
                    margin-right: 20px;
                }
            }

            
        }

        .contactUs {
            display: flex;
            flex-direction: column;

            h5 {
                font-size: 14px;
                font-family: Montserrat,SF-UI;
                font-weight: Medium;
                color: #330000;
                padding-bottom: 30px;
                text-align: center;
            }
            .contactUs-img {
                display: flex;
                justify-content: space-between;

                .el-image {
                    margin-left: 35px;
                }
                .el-image:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .about-content-bottom {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
            font-size: 12px;
            font-family: Montserrat,SF-UI;
            font-weight: 400;
            color: #330000;
            line-height: 1;
            padding-bottom: 14px;
            padding-top: 40px;
        }

        .text{
            display: flex;

            u{
                font-size: 12px;
                font-family: Montserrat;
                font-weight: 400;
                color: #68696A;
                padding-right: 20px;
                padding-bottom: 30px;
                line-height: 1;
            }
        }
        // p {
        //     font-size: 14px;
        //     font-family: Montserrat,SF-UI;
        //     font-weight: 300;
        //     text-decoration: underline;
        //     color: #330000;
        // }
    }
}
</style>

<script>
export default {
    name: "commonFooter",
    components: {},
    data: () => {
        return {
            background: "",
            show: true,
            // 需要翻译的静态文本
            translateTextData: {
                COMPANY_INFO: 'COMPANY INFO',
                About_SOYEGO: 'About SOYEGO',
                Fashion_Blogger: 'Fashion Blogger',
                HELP_SUPPORT: 'HELP & SUPPORT',
                Shipping_Info: 'Shipping Info',
                Returns_Policy: 'Returns Policy',
                How_To_Order: 'How To Order',
                Size_Guide: 'Size Guide',
                Contact_US: 'Contact US',
                FAQ: 'FAQ',
                How_To_Pay: 'How To Pay',
                FIND_US_ON: 'FIND US ON',
                WE_ACCEPT: 'WE ACCEPT',
                Copyright_Notice: 'Copyright Notice',
                Privacy_Policy: 'Privacy & Cookie Policy',
                Terms_and_Conditions: 'Terms and Conditions'
            }
        }
    },
    watch: {
        $route: "fetchData"
    },
    created() {
        this.getTranslateText();
    },
    mounted() {
        if (this.$route.path == "/login" || this.$route.path == "/personal/logistics") {
            this.show = false
        } else {
            this.show = true
        }
        if (this.$route.path == "/personal/myAccount" || this.$route.path == "/personal/modifyPassword") {
            this.background = "background: #F7F9FA;"
        } else {
            this.background = ""
        }
    },
    methods: {
        // 翻译静态文本
        getTranslateText() {
            let strText = '';
            let i = 0;
            let translateData = Object.values(this.translateTextData); // 创建一个包含对象中每个属性的值的数组
            translateData.forEach((item,index) => {
                strText += item + '\n'
            })
            // 翻译颜色
            // this.getTransColor()
            // 翻译
            this.$fanyi.translateData({
                query: strText,
                success: resData => {
                    for(let key in this.translateTextData){
                        this.translateTextData[key] = resData[i]
                        i++;
                    }
                }
            })
        },
        textImgClick(val) {
            window.location.href = val;
        },
        fetchData() {
            if (this.$route.path == "/login" || this.$route.path == "/personal/logistics") {
                this.show = false
            } else {
                this.show = true
            }
            if (this.$route.path == "/personal/myAccount" || this.$route.path == "/personal/modifyPassword") {
                this.background = "background: #F7F9FA;"
            } else {
                this.background = ""
            }
        }
    }
}
</script>
