<template>
    <div>
        <div class="header-top">
            <img src="@/assets/images/header/head2.png" fit="cover" />
        </div>
    </div>
</template>

<script>
// import { mapGetters } from "vuex"
// import { getToken } from "@/utils/auth"
import { diyviewInfo } from '@/api/pc';
export default {
    props: {},
    data() {
        return {
            titleTop: ""
        }
    },
    created() {
        // this.$store.dispatch("site/qrCodes")
        // this.$store.dispatch("member/member_detail")
        // this.$store.dispatch("site/defaultFiles")
        // this.$store.dispatch("site/addons")
    },
    mounted() {
        this.getDiyviewInfo();
    },
    watch: {},
    methods: {
        getDiyviewInfo() {
			const params = {
				name: "DIY_VIEW_INDEX",
				website_id: 0
			}
			var that = this;
			diyviewInfo(params || {})
				.then(res => {
					let data = JSON.parse(res.data.value);
					let value = data.value;
					value.forEach(item => {
						if(item.name == "文本" && item.type == "TEXT"){
							that.titleTop = item.title;
						}
					})
				})
				.catch(err => {
					// console.log(err)
				})
		},
    },
    components: {},
    computed: {
        // ...mapGetters(["wapQrcode", "member", "addonIsExit", "city"]),
        // qrcode: function() {
        //     return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
        // },
        // logined: function() {
        //     return this.member !== undefined && this.member !== "" && this.member !== {}
        // }
    }
}
</script>

<style scoped lang="scss">
.header-top {
    width: 100%;
    // padding: 17px 0;
    font-size: 12px;
    // background: #330000;
    text-align: center;
    font-size: 14px;
    font-family: Montserrat,SF-UI;
    font-weight: bold;
    color: #ffffff;
}
</style>
